import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets"; 
import { toast } from "react-toastify";
import {loadStripe} from '@stripe/stripe-js';
import { Stripe,StripeElements, StripeElementsOptionsClientSecret, StripeElementsOptionsMode,StripeCardNumberElementChangeEvent } from "@stripe/stripe-js/types/stripe-js";
import {getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import {CardNumberElement} from '@stripe/react-stripe-js';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formValues :{
      firstName: string,
      lastName: string,
      addressLine1: string,
      addressLine2: string,
      city: string,
      state: string,
      zipCode: string,
  }
  loading: boolean;
  subscriptionPlan: any,
  token: string,
  stripeState: Stripe | null,
  elementState: StripeElements | null,
  cardErrors:any,
  cardNumber: string;
  updateCardUserDetails:{
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    zipCode: string;
    paymentMethodId: string;
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubscriptionPlanApiCallId: string = "";
  processPaymentCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardNumber: "",
      formValues :{
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        },
      loading: false,
      subscriptionPlan:{},
      token:"",
      stripeState: null,
      elementState: null,
      cardErrors:{},
      updateCardUserDetails: {
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        paymentMethodId: ''
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
   
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } 
        else if (apiRequestCallId == this.processPaymentCallId) {
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          this.handleSubscriptionData(responseJson)
        }
        else if (apiRequestCallId == this.getSubscriptionPlanApiCallId) {
           this.setState({'subscriptionPlan' : responseJson.subscription_plan })
        }
        
      } 
      this.setState({ loading: false });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const tokenMsg = await getStorageData("token")

    if(tokenMsg){
      this.setState({
        token:tokenMsg
      })
    } 
    this.getSubscriptionPlan()
    window.scrollTo(0, 0);
    const updateCardUserDetails = await getStorageData("updateCardUserDetails", true);    
    if(updateCardUserDetails) {
      this.setState({cardNumber: updateCardUserDetails.card_number})
    }
   }

   
 
   getSubscriptionPlan = ()=>{  
    this.setState({ loading: true });
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionPlanApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getSubscriptionPlanApiEndPoint
      '/bx_block_subscription_billing/subscription_plan/show_active_plan'
        );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionPlanApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loading: false });
  };

  stripePromise = loadStripe(configJSON.STRIPE_PUBLISHABLE_KEY);


  options: StripeElementsOptionsClientSecret | StripeElementsOptionsMode = {
    // mode: 'subscription',
    // amount: 99,
    // currency: 'usd',
    appearance: {
      theme: 'stripe',
    },
  };

  handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
    const allowedBrands = ['visa', 'mastercard', 'amex'];
    if (event.complete) {
        const detectedBrand = event.brand;
        if (!allowedBrands.includes(detectedBrand)) {
            this.setState({ 
              cardErrors: { ...this.state.cardErrors, cardNumberError: "Only Visa, Mastercard, and American Express are accepted." } 
            });
        } else {
            this.setState({ 
              cardErrors: { ...this.state.cardErrors, cardNumberError: "" } 
            });
        }
    } 
    else if (event.error) {
        this.setState({ 
          cardErrors: { ...this.state.cardErrors, cardNumberError: event.error.message }
        });
    }
    };

    handleError = (error: any) => { let cardNumberError = error.type?.includes("card_error") ? error.message || error.code : ""; let cardExpiryError = error.code?.includes("invalid_expiry_") ? error.message || error.code : ""; let cardCVCError = error.code?.includes("incomplete_cvc") ? error.message || error.code : ""; this.setState({ cardErrors: { ...this.state.cardErrors, cardNumberError, cardExpiryError: cardExpiryError, cardCVCError: cardCVCError } }); }


    handleErrorColor=(condition:boolean,colorCode1:string,colorCode2:string)=>{
      if(condition) return colorCode1;
      else return colorCode2;
    }
  handleSubmit = async (values: any, stripe: Stripe | null, elements: StripeElements | null) => {
    this.setState({
      stripeState: stripe,
      elementState: elements,
    });

    if (!this.state.stripeState || !this.state.elementState || this.state.cardErrors.cardNumberError) { console.error("Stripe reference or Elements not found."); return; }

    const cardNumberElement = this.state.elementState.getElement(CardNumberElement);
    if (!cardNumberElement) { return;}

      const {error,paymentMethod} = await this.state.stripeState.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: values.firstName,
          address: {
            city: values.city,
            line1: values.addressLine1,
            line2: values.addressLine2,
            postal_code: values.zipCode,
            state: values.state,
          },
        },
        metadata: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });

      if (error) {
        this.handleError(error);
      }
      if (paymentMethod && paymentMethod.id) {
        this.setValues({ ...values, paymentMethodId: paymentMethod.id, card_number: paymentMethod.card?.last4 })
      }

  };

  setValues = (values: any) => {
    setStorageData("updateCardUserDetails", JSON.stringify(values));
    this.props.navigation.navigate('PaymentConfirmation')
  }
  
  paymentProcessingMethod = async () => {
    setStorageData('isShowProgress' , true) 
    const updateCardUserDetails = await getStorageData("updateCardUserDetails", true);
    const header = {
      token: this.state.token
    };
    const formdata = new FormData();
    formdata.append("subscription[subscription_plan_id]", this.state.subscriptionPlan.id);
    formdata.append("subscription[address_line_1]", updateCardUserDetails.addressLine1);
    formdata.append("subscription[address_line_2]", updateCardUserDetails.addressLine2);
    formdata.append("subscription[state]", updateCardUserDetails.state);
    formdata.append("subscription[city]", updateCardUserDetails.city);
    formdata.append("subscription[zipcode]", updateCardUserDetails.zipCode);
    formdata.append("subscription[token_stripe]", updateCardUserDetails.paymentMethodId);    

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.processPaymentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentProcessingEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleSubscriptionData = async (responseJson:any) => {
  if(responseJson.error) {
    toast.error(responseJson.error)
    return
  }
  if(responseJson.subscription) {
      await removeStorageData("updateCardUserDetails")
      this.props.navigation.navigate('LandingPage')
    }
}
  // Customizable Area End
}

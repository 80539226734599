import React from 'react'
import { Stack, styled, Typography, Box } from "@mui/material"
import { Actions } from '../Settings2Controller';
const { settings } = require("../config")
import { SidebarData } from "../Helper"

interface Props extends React.PropsWithChildren {
    action: { type: Actions, active: boolean };
    changeAction: any;
    tab: boolean;
    onClickTab: any;
    isMobileScreen: boolean;
}

const Sidebar = ({ children, action, changeAction, tab }: Props) => {
    const getBar = ({ action, title, changeAction, props }: { action: Actions, title: string, changeAction: any, props?: any }) => {
        return (
            <Stack style={{ cursor: "pointer" }}>
                <Typography className='font-style nav-item' onClick={() => {
                    changeAction(action)
                }} {...props} display={"flex"} direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{
                    "@media(max-width: 991px)": {
                        height: "72px !important",
                        borderBottom: "1px solid #E2E8F0 !important"
                    }
                }}>
                    {title}
                    <Box sx={{
                        display: "none",
                        "@media(max-width: 991px)": {
                            display: "flex"
                        }
                    }}>
                        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.997666 19.0147C1.48767 19.5047 2.27767 19.5047 2.76767 19.0147L11.0777 10.7047C11.4677 10.3147 11.4677 9.68469 11.0777 9.29469L2.76767 0.984688C2.27767 0.494687 1.48767 0.494687 0.997666 0.984688C0.507666 1.47469 0.507666 2.26469 0.997666 2.75469L8.23767 10.0047L0.987666 17.2547C0.507666 17.7347 0.507666 18.5347 0.997666 19.0147Z" fill="#94A3B8" />
                        </svg>
                    </Box>
                </Typography>
            </Stack>
        )
    }
    return (
        <Stack direction={"row"} sx={{
            p: 0, width: "calc(100% - 100px)",
            "@media(max-width: 991px)": {
                width: "100%",
                minHeight: "700px"
            }
        }}>
            <CustomSidebar sx={{ display: tab ? "block" : "none" }}>
                <div className='side-header flex items-center'>
                    <Typography className='font-style'>{settings.settingTxt}</Typography>
                </div>
                <Stack sx={{
                    p: 3,
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "90%",
                    "@media(max-width: 991px)": {
                        py: 0,
                        px: 3,
                        justifyContent: "flex-start",
                        gap: "48px",
                        background: "#fff"
                    }
                }}
                >
                    <Stack gap={"28px"} sx={{
                        height: "218px",
                        maxWidth: "204px",
                        width: "100%",
                        "@media(max-width: 991px)": {
                            maxWidth: "100%",
                            height: "100%",
                            gap: 0
                        }
                    }}>
                        <Typography sx={{
                            fontSize: "14px", color: "#94A3B8", fontWeight: 700, fontFamily: "Inter",
                            display: "none",
                            "@media(max-width: 991px)": {
                                display: "block"
                            }
                        }}>General</Typography>
                        {SidebarData.slice(0, 3).map((item: any, index: number) => getBar({
                            ...item, changeAction, props: {
                                style: {
                                    height: "54px",
                                    borderBottom: item.action !== Actions.CHANGE_PASSWORD ? "1px solid #E2E8F0" : "none",
                                    color: item.action === action.type ? "#D97706" : "#334155",
                                }
                            },
                            key: index
                        }))}
                    </Stack>
                    <Stack gap={"32px"} sx={{
                        maxWidth: "204px",
                        width: "100%",
                        "@media(max-width: 991px)": {
                            maxWidth: "100%",
                            height: "100%",
                            gap: "48px"
                        }
                    }}>
                        <Stack sx={{
                            "@media(max-width: 991px)": {
                                gap: 0
                            }
                        }} gap={"28px"}>
                            <Typography sx={{
                                fontSize: "14px", color: "#94A3B8", fontWeight: 700, fontFamily: "Inter",
                                display: "none",
                                "@media(max-width: 991px)": {
                                    display: "block"
                                }
                            }}>Documents</Typography>
                            {SidebarData.slice(3, 5).map((item) => getBar({
                                ...item, changeAction, props: {
                                    style: { color: "334155" }
                                }
                            }))}
                        </Stack>
                        <Stack gap={"28px"} sx={{
                            "@media(max-width: 991px)": {
                                gap: "32px"
                            }
                        }}>
                            <Typography className='font-style' style={{ cursor: "pointer", fontWeight: 700, fontSize: "16px", color: "#334155" }} onClick={() => changeAction(Actions.DELETE_ACCOUNT)}>{settings.deleteAccountTxt}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomSidebar>
            <Box sx={{
                width: "100%",
                display: "flex",
                py: 8,
                px: 16,

                "@media(max-width: 1200px)": {
                    px: 4,
                    py: 8
                },
                "@media(max-width: 991px)": {
                    p: 3,
                    display: !tab ? "flex" : "none"
                },
            }}>{children}</Box>
        </Stack>
    )
}

const CustomSidebar = styled(Stack)({
    minWidth: "308px",
    height: "calc(100vh - 9vh)",
    background: "#F8FAFC",
    borderRight: "1px solid #E2E8F0",

    "& .flex": {
        display: "flex",
    },
    "& .items-center": {
        alignItems: "center"
    },
    "& .justify-center": {
        justifyContent: "center"
    },
    "& .side-header": {
        height: "77px",
        background: "#F1F5F9",
        padding: "24px"
    },
    "& .font-style": {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        color: "#000000"
    },
    "& .nav-item": {
        fontSize: "16px",
        color: "#334155"
    },
    "& .active": {
        color: "#D97706",
    },
    "& .logout": {
        color: "#F87171",
        borderBottom: "1px solid #F87171",
        width: "fit-content"
    },
    "@media(max-width: 991px)": {
        minWidth: "100%",
        height: "100%",
        border: "none",

        "& .side-header": {
            background: "#fff"
        }
    }
})

export default Sidebar
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { headLogo, sidePic } from "./assets"
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export enum OtpVerificationEnum {
  PHONE_OTP_VERIFICATION,
  EMAIL_OTP_VERIFICATION,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  emailOtp:string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  head: string;
  sidePic: string;
  otpError: boolean;
  otpEmailError: boolean;
  phoneNumber: string;
  verification:boolean;
  email:string;
  success:boolean;
  verificationType: OtpVerificationEnum;
  resendCodeSent: boolean;
  otpEmailErrorMessage: string | null;
  otpMobileErrorMsg: string | null;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  otpVerifyApiCallId: string = "";
  emailOtpVerifyApiCallId:string = "";
  resendOtpCallId: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      emailOtp:"",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      head: headLogo.default,
      sidePic: sidePic.default,
      otpError: false,
      otpEmailError: false,
      phoneNumber:'',
      verification:false,
      email:'',
      success: false,
      verificationType: OtpVerificationEnum.PHONE_OTP_VERIFICATION,
      resendCodeSent: false,
      otpEmailErrorMessage: null,
      otpMobileErrorMsg: null,
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        (responseJson.messages ||
          (responseJson.meta && responseJson.meta.token))
      ) {
        if (responseJson.meta && responseJson.meta.token) {
          this.setState({
            otpAuthToken: responseJson.meta.token,
          });
        }

        if (this.state.isFromForgotPassword) {
          // runEngine.debugLog("about to send NavigationNewPasswordMessage");
          const msg: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordMessage)
          );
          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        } else {
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        }
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool,
      });
    }
    this.handleResendOtpResponse(apiRequestCallId, message)
    this.receive1(message)
    // Customizable Area End
  }

  // Customizable Area Start
  
  receive1 = async (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId === this.otpVerifyApiCallId) {
      this.handleMobileOtpResponse(responseJson);
    } else {
      this.receive2(message); 
    }
  };

  handleMobileOtpResponse = (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      this.handleMobileOtpSuccess(); 
    } else {
      this.handleMobileOtpFailure(responseJson); 
    }
  };

  handleMobileOtpSuccess = async () => {
    this.setState({
      verification: false,
      verificationType: OtpVerificationEnum.EMAIL_OTP_VERIFICATION,
      resendCodeSent: false,
      otpError: false,                  
      otpMobileErrorMsg: ''           
    });
    localStorage.removeItem("isValidation");
    await setStorageData("isValidation", false);
  };

  handleMobileOtpFailure = (responseJson: any) => {
    const errorMessage = this.getMobileOtpErrMsg(responseJson);
    this.setState({
      otpError: true,
      otpMobileErrorMsg: errorMessage
    });
  };

  getMobileOtpErrMsg = (responseJson: any): string => {
    if (responseJson.error === "Incorrect code, please try again") {
      return "Incorrect code, please try again";
    } else if (responseJson.errors?.[0] === "OTP has expired, please request a new one") {
      return "Code has expired, please request a new one";
    } else {
      return "Incorrect code, please try again";
    }
  };

  isfrom = (isFrom:any) => {
    if (isFrom === "SignUp"){
      this.setState({ success:true });
      setTimeout(()=>{
        this.navToo("LandingPage");
      },3000);
    } else {
        this.navToo("LandingPage");
    }
  }

  receive2 = async (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return; 
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId === this.emailOtpVerifyApiCallId) {
      this.handleOtpResponse(responseJson);
    }
  };
  
  handleOtpResponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      const isFrom = await getStorageData("isFrom");
      this.isfrom(isFrom);
    } else {
      const errorMessage = this.getOtpErrorMessage(responseJson.errors[0]);
      this.setState({
        otpEmailError: true,
        otpEmailErrorMessage: errorMessage
      });
    }
  };
  
  getOtpErrorMessage = (error: any) => {
    const otpError = error?.otp;
    const otpExpiredError = error;
  
    if (otpError === "Invalid OTP") {
      return "Incorrect code, please try again";
    }
    
    if (otpExpiredError === "OTP has expired, please request a new one") {
      return "Code has expired, please request a new one";
    }
  
    return "An unknown error occurred";
  };
  
  

  navToo = (name:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      name
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

   async componentDidMount(): Promise<void> {
    window.addEventListener('isValidation',this.handlegetItem);
    this.handlegetItem()
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  
  handlegetItem = async()=>{
    const isValidation = await getStorageData("isValidation");
    const phoneNumber = await getStorageData("phoneNumber");
    const emailAddress = await getStorageData("emailAddress");
    if(isValidation){
      this.setState({ verification: isValidation });
    }else{
      this.setState({ verification: true });
    }
    
    this.setState({ phoneNumber: phoneNumber });
    this.setState({ email: emailAddress });
  }
  handleOtp = (otpnumber: string) => {
    this.setState({ otpError: false });
    this.setState({ otp: otpnumber });
    this.setState({ resendCodeSent: false});
  };

  handleSubmit = async() => {
    this.setState({emailOtp: '', otpEmailError: false, resendCodeSent: false, otpMobileErrorMsg: 'Incorrect code, please try again'});
    if (this.state.otp === '') {
      this.setState({ otpError: true, resendCodeSent: false });
    } else {
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        "token" : token
      };
      const data = {
        mobile_otp: this.state.otp
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpVerifyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.otpVerifyApi
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleResendCode = async() => {
    this.setState({otpError: false, otpEmailError: false, resendCodeSent: false, otp: '', emailOtp: ''});
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(this.state.verificationType === OtpVerificationEnum.PHONE_OTP_VERIFICATION){
      const httpBody = {
        data: {
          full_phone_number: this.state.phoneNumber
        }
      };
      this.resendOtpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.phoneResendCodeApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const httpBody = {
        data: {
          email: this.state.email
        }
      };
      this.resendOtpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailResendCodeApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleResendOtpResponse = async(id: string, message:Message) => {
    if(id === this.resendOtpCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors?.length) {
        this.setState(() => ({otpError: true}))
      }
      else if(responseJson.message) {
        this.setState({resendCodeSent: true});
        setTimeout(()=>{
          this.setState({
            resendCodeSent: false
          })
        },3000)
      }
    }
  }
  

  navTo = () => {
    this.props.navigation.goBack();
  }

  back2 = async() => {
    await setStorageData("isValidation",true);
    window.dispatchEvent(new Event('isValidation'));
    this.setState({ otpEmailError: false, resendCodeSent: false, otp: '' });
  }

  handleEmailOtp = (otpnumber:string) => {
    this.setState({ otpEmailError: false });
    this.setState({ emailOtp: otpnumber });
    this.setState({ resendCodeSent: false})
  }

  OtpEmail = async() => {
    if (this.state.emailOtp === '') {
      this.setState({ otpEmailError: true, resendCodeSent: false, otpEmailErrorMessage: 'Incorrect code, please try again' });
    } else {
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        "token" : token
      };
      const data = {
        email_otp: this.state.emailOtp
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.emailOtpVerifyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailOtpVerifyApi
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, logo, bgImage, sidePic } from "./assets";
import  {setStorageData, getStorageData, removeStorageData} from "../../../framework/src/Utilities";
import moment from "moment";

export enum OtpVerificationEnum {
  PHONE_OTP_VERIFICATION,
  EMAIL_OTP_VERIFICATION,
  NONE
}

export enum AuthenticationMethod {
  EMAIL,
  PHONE
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  head: string;
  sidePic: string;
  signin:boolean;
  inputErrors: {
    emailError: boolean,
    passwordError: boolean
  };
  showPassword: boolean;
  newMember: boolean;
  existingMember: boolean;
  otp: string;
  emailOtp:string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  rightPic: string;
  otpError: boolean;
  otpEmailError: boolean;
  phoneNumber: string;
  verification:boolean;
  userEmail:string;
  success:boolean;
  verificationType: OtpVerificationEnum;
  resendCodeSent: boolean;
  AuthMethod: AuthenticationMethod,
  otpEmailErrorMessage: string | null;
  otpPhoneErrorMessage: string | null;
  phoneNotVerified:boolean;
  emailNotVerified:boolean;
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  signinAccountApiCallId: string = "";
  phoneOtpVerifyApiCallId: string = "";
  emailOtpVerifyApiCallId:string = "";
  resendOtpCallId: string = "";
  getAuthMethodCallId: string = "";
  getSubscriptionCallId: string = "";
  otpVerifyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      head: logo.default,
      sidePic: bgImage.default,
      signin : true,
      inputErrors: {
        emailError: false,
        passwordError: false
      },
      showPassword:false,
      newMember:  true,
      existingMember: false,
      otp: "",
      emailOtp:"",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      rightPic: sidePic.default,
      otpError: false,
      otpEmailError: false,
      phoneNumber:'',
      verification:false,
      userEmail:'',
      success: false,
      verificationType: OtpVerificationEnum.PHONE_OTP_VERIFICATION,
      resendCodeSent: false,
      AuthMethod: AuthenticationMethod.PHONE,
      otpEmailErrorMessage: null,
      otpPhoneErrorMessage: null,
      phoneNotVerified:false,
      emailNotVerified:false,
      errorMessage: "Incorrect login credentials, please try again"
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if (window.location.pathname == "/") {
      await removeStorageData('phoneNotVerified')
      await removeStorageData('emailNotVerified')
    }
    const isOk = await getStorageData("Signin");
    if (isOk){
      this.setState({ signin: true });
      localStorage.removeItem("new-member")
    } else {
      this.setState({ signin: false });
    }
    this.getAuthMethod();
    window.addEventListener('isValidation',this.handleItems);
    this.handleItems()
    window.scrollTo(0, 0);

    let newMember: any = localStorage.getItem("new-member")
    if(newMember) {
      newMember = JSON.parse(newMember)
      this.setState({newMember: newMember, existingMember: !newMember })
    }

    if (window.location.pathname == "/PendingVerifyOtp" ) {
      let phoneNotVerified = await getStorageData("phoneNotVerified")
      let emailNotVerified = await getStorageData("emailNotVerified")
      let emailAddress = await getStorageData("emailAddress");
      let phoneNumber = await getStorageData("phoneNumber");

      if(phoneNotVerified){
        phoneNotVerified=JSON.parse(phoneNotVerified);
        this.setState({
          phoneNotVerified,
          verificationType: OtpVerificationEnum.PHONE_OTP_VERIFICATION,
          email: emailAddress,
          phoneNumber: phoneNumber,
          AuthMethod: AuthenticationMethod.PHONE
        })
      }
      else if(emailNotVerified){
        emailNotVerified=JSON.parse(emailNotVerified);
        this.setState({
          emailNotVerified,
          verificationType: OtpVerificationEnum.EMAIL_OTP_VERIFICATION,
          email: emailAddress,
          phoneNumber: phoneNumber,
          AuthMethod: AuthenticationMethod.EMAIL
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  signin = () => {
    const { email , password, inputErrors } = this.state;
    if( email==="" && password==="" ) {
      this.setState({ inputErrors: {...inputErrors, passwordError:true,emailError:true } });
    }
    else if( email==="" && password!=="" ) {
      this.setState({ inputErrors: {...inputErrors, emailError:true} });
    }
    else if( password==="" && email!=="" ) {
      this.setState({ inputErrors: {...inputErrors, passwordError:true} });
    }
    else {
      const header = {
        "Content-Type": configJSON.validationApiContentType
      };
      const attrs = {
        email: this.state.email,
        password: this.state.password,
      };
      const data = {
        type: "email_account",
        attributes: attrs
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.signinAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userLoginApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  signup = async () => {
    if(this.state.signin) {
      this.setState({newMember: true, existingMember: false})
    }
    this.setState({ signin: !this.state.signin });
    localStorage.removeItem("Signin");
    localStorage.removeItem("new-member");
    setStorageData("Signin", !this.state.signin);
  }

  goTo = () => {
    let name = "";
    localStorage.setItem("new-member", JSON.stringify(this.state.newMember || !this.state.existingMember))
    if (this.state.newMember){
      name = "EmailAccountRegistration"
    } else if (this.state.existingMember){
      name = "ExistingMemberRegistration"
    }
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), name);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  handleInput =  (e:any) => {
    let { name, value } = e.target;
    switch(name) {
      case "email address":
          this.setState({ email: value });
          this.setState({ inputErrors: {...this.state.inputErrors, emailError: false} });
        break;
      case "password":
        this.setState({ password: value });
        this.setState({ inputErrors: {...this.state.inputErrors, passwordError: false} });
        break;
    }
  }

  handlemember = () => {
    this.setState({ newMember: !this.state.newMember , existingMember: !this.state.existingMember })
  }

  userLogin = async(responseJson:any) => {
    if (!responseJson.errors) {
      this.setState({ inputErrors: {...this.state.inputErrors, emailError: false, passwordError: false} });
      await setStorageData("phoneNumber",responseJson.meta.full_phone_number);
      await setStorageData("emailAddress",this.state.email);
      await setStorageData("token",responseJson.meta.token);
      localStorage.removeItem("isFrom");
      this.getSubscription();
    } else if (responseJson.errors) {
      let errorMessage = "Incorrect login credentials, please try again";
      if(responseJson.errors?.[0]?.failed_login === "Account not found") {
        errorMessage = responseJson.errors[0].failed_login;
      }
      this.setState({ inputErrors: {...this.state.inputErrors, emailError: true, passwordError: true}, errorMessage });
    }
  }

otpVerify = (name:string) => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), name);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}
  
  receive1 = (from:string, message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.signinAccountApiCallId) {
          this.handleSigninErrors(responseJson)
        }
      }
    }
  }

  navigateToForgetPassword=()=>{
    this.props.navigation.navigate('ForgotPasswordWeb')
  }

  handleSigninErrors = async (responseJson: any) => {
    if (responseJson.pending_verification && Array.isArray(responseJson.pending_verification)) { 
      let mobileError = false;
      let emailError = false;
      this.setPendingVerificationData(responseJson,mobileError,emailError)
      this.props.navigation.navigate("PendingVerification");
    } else {
      this.userLogin(responseJson);
    }
  };
  
  
  setPendingVerificationData = (responseJson: any,mobileError:boolean,emailError:boolean) => {
    responseJson.pending_verification.forEach((verification: any) => {
      if (verification.message === 'Mobile number not verified') {
        mobileError = true;
      } else if (verification.message === 'Email not verified') {
        emailError = true;
      }
    });

    if (mobileError) {
      setStorageData("phoneNotVerified", true);
    }
    if (emailError) {
      setStorageData("emailNotVerified", true);
    }

    setStorageData("emailAddress", responseJson.meta.email);
    setStorageData("phoneNumber", responseJson.meta.full_phone_number);
    setStorageData("token", responseJson.meta.token);
    setStorageData("verificationPending", true);
  };

  continueVerification=async()=>{
    this.props.navigation.navigate("PendingVerifyOtp")
  }
  
  receive2 = async (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId === this.phoneOtpVerifyApiCallId) {
      this.handlePhoneOtpResponse(responseJson);
    } else {
      this.receive3(message); 
    }
  };

  handlePhoneOtpResponse = async (responseJson: any) => {
    if (!responseJson.error && !responseJson.errors) {
      await this.handleOtpSuccess();
    } else {
      this.handleOtpFailure(responseJson);
    }
  };
 
  handleOtpSuccess = async () => {
    if (this.props.navigation.state.routeName === "/PendingVerifyOtp") {
      let emailNotVerified = await getStorageData("emailNotVerified")
      if(emailNotVerified) {
        emailNotVerified = JSON.parse(emailNotVerified)
        this.setState({verificationType: !!emailNotVerified ? OtpVerificationEnum.EMAIL_OTP_VERIFICATION : OtpVerificationEnum.NONE})
      }
    } else {
      this.setState({verificationType: OtpVerificationEnum.EMAIL_OTP_VERIFICATION})
      this.setState({ 
        verification: false, 
        resendCodeSent: false,
        otpError: false,               
        otpPhoneErrorMessage: '',       
      });
      this.navToo("LandingPage");
      localStorage.removeItem("isValidation");
      await setStorageData("isValidation", false);
      await removeStorageData("phoneNotVerified")
    }
  };

  handleOtpFailure = (responseJson: any) => {
    let errorMessage = this.getPhoneOtpErrMsg(responseJson);
    this.setState({ 
      otpError: true, 
      otpPhoneErrorMessage: errorMessage 
    });
  };


  getPhoneOtpErrMsg = (responseJson: any): string => {
    if (responseJson.error === "Incorrect code, please try again") {
      return "Incorrect code, please try again";
    }
    if (responseJson.errors?.[0] === "OTP has expired, please request a new one") {
      return "Code has expired, please request a new one";
    }
    return "Incorrect code, please try again";
  };


  receive3 = async (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return; 
    }
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId === this.emailOtpVerifyApiCallId) {
      this.handleOtpResponse(responseJson);
    }
  };

  handleOtpResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        otpEmailError: false,
        otpEmailErrorMessage: null,
      });
      this.navToo("LandingPage");
    } else {
      const displayError = this.getDisplayError(responseJson.errors[0]);
      this.setState({
        otpEmailError: true,
        otpEmailErrorMessage: displayError,
      });
    }
  };

  getDisplayError = (error: { otp?: string } | string): string => {
    if (typeof error !== 'string' && error.otp === "Invalid OTP") {
      return "Incorrect code, please try again";
    }
  
    if (typeof error === 'string' && error === "OTP has expired, please request a new one") {
      return "Code has expired, please request a new one";
    }
  
    return "Incorrect code, please try again";
  };

  navToo = (name:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      name
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  navTo = () => {
    this.props.navigation.goBack();
  }


  handlePhoneOtp = (otpnumber: string) => {
    this.setState({ otpError: false });
    this.setState({ otp: otpnumber });
    this.setState({ resendCodeSent: false});
  };

  back2 = async() => {
    await setStorageData("isValidation",true);
    window.dispatchEvent(new Event('isValidation'));
    this.setState({ otpEmailError: false, resendCodeSent: false, otp: '' });
  }

  navToPhoneVerification = async() => {
    const phoneNotVerified= await getStorageData("phoneNotVerified")
    if(phoneNotVerified===null){
      this.props.navigation.goBack();
    }
    else{
      this.setState({ emailNotVerified: false, phoneNotVerified: true ,otp: ""  });
    }
  }

  handleItems = async()=>{
    const isValidated = await getStorageData("isValidation");
    const mobileNumber = await getStorageData("phoneNumber");
    const emailAddress = await getStorageData("emailAddress");
    if(isValidated){
      this.setState({ verification: isValidated });
    }else{
      this.setState({ verification: true });
    }
    
    this.setState({ phoneNumber: mobileNumber });
    this.setState({ email: emailAddress });
  }

  handlePhoneSubmit = async() => {
    this.setState({emailOtp: '', otpEmailError: false, resendCodeSent: false, otpPhoneErrorMessage: 'Incorrect code, please try again'});
    if (this.state.otp === '') {
      this.setState({ otpError: true, resendCodeSent: false });
    } else {
      const authToken = await getStorageData("token");
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        "token" : authToken
      };
      const data = {
        mobile_otp: this.state.otp
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const requestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.phoneOtpVerifyApiCallId = requestMsg.messageId;
      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.otpVerifyApi
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
  
      runEngine.sendMessage(requestMsg.id, requestMsg);
      return true;
    }
  }

  handleEmailAddOtp = (otpnumber:string) => {
    this.setState({ otpEmailError: false });
    this.setState({ emailOtp: otpnumber });
    this.setState({ resendCodeSent: false})
  }

  handleResendOtp = async() => {
    this.setState({otpError: false, otpEmailError: false, resendCodeSent: false, otp: '', emailOtp: ''});
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(this.state.AuthMethod === AuthenticationMethod.PHONE){
      const httpBody = {
        data: {
          full_phone_number: this.state.phoneNumber
        }
      };
      this.resendOtpCallId = reqMessage.messageId;
      reqMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.phoneResendCodeApi
      );
      reqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const httpBody = {
        data: {
          email: this.state.email
        }
      };
      this.resendOtpCallId = reqMessage.messageId;
      reqMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailResendCodeApi
      );
      reqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    }

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleResendPhoneOtp = async () => {
    this.setState({ otpError: false, otpEmailError: false, resendCodeSent: false, otp: '', emailOtp: '' });
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      data: {
        full_phone_number: this.state.phoneNumber
      }
    };
    this.resendOtpCallId = reqMessage.messageId;
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneResendCodeApi
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleResendEmailOtp = async () => {
    this.setState({ otpError: false, otpEmailError: false, resendCodeSent: false, otp: '', emailOtp: '' });
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const httpBody = {
      data: {
        email: this.state.email
      }
    };
    this.resendOtpCallId = reqMessage.messageId;
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailResendCodeApi
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleResendCodeRes = async(id: string, message:Message) => {
    if(id === this.resendOtpCallId){
      const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(resJson.error) return
      if(resJson.errors?.length) {
        this.setState(() => ({otpError: true}))
      }
      else if(resJson.message) {
        this.setState({resendCodeSent: true});
        setTimeout(()=>{
          this.setState({
            resendCodeSent: false
          })
        },3000)
      }
    }  
  }

  handleOtpEmail = async() => {
    if (this.state.emailOtp === '') {
      this.setState({ otpEmailError: true, resendCodeSent: false, otpEmailErrorMessage: 'Incorrect code, please try again', otp: '' });
    } else {
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        "token" : token
      };
      const data = {
        email_otp: this.state.emailOtp
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const reqMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.emailOtpVerifyApiCallId = reqMsg.messageId;
      reqMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailOtpVerifyApi
      );
  
      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      reqMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
  
      runEngine.sendMessage(reqMsg.id, reqMsg);
      return true;
    }
  }

  handleOtp = (otpnumber: string) => {
    this.setState({ otpError: false });
    this.setState({ otp: otpnumber });
    this.setState({ resendCodeSent: false});
  };

  handleSubmit = async() => {
    this.setState({
      emailOtp: '',
      otpEmailError: false,
      resendCodeSent: false,
      otpPhoneErrorMessage: 'Incorrect code, please try again',
      phoneNotVerified: false,
      emailNotVerified: true,
      AuthMethod: AuthenticationMethod.PHONE
    });
    if (this.state.otp === '') {
      this.setState({ otpError: true, resendCodeSent: false });
    } else {
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        "token" : token
      };
      const data = {
        mobile_otp: this.state.otp
      };
      const httpBody = {
        data: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.phoneOtpVerifyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.otpVerifyApi
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleEmailOtp = (otpnumber:string) => {
    this.setState({ otpEmailError: false });
    this.setState({ emailOtp: otpnumber });
    this.setState({ resendCodeSent: false})
  }

  OtpEmail = async() => {
    if (this.state.emailOtp === '') {
      this.setState({ otpEmailError: true, resendCodeSent: false, otpEmailErrorMessage: 'Incorrect code, please try again' });
    } else {
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        "token" : token
      };
      const data = {
        email_otp: this.state.emailOtp
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.emailOtpVerifyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailOtpVerifyApi
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  redirectToSignInPage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getAuthMethod = async() => {
    const authToken = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAuthMethodCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.authMethodEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetAuthResponse = async(id: string, message:Message) => {
    if(id === this.getAuthMethodCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.errors && responseJson.errors[0].token && !await getStorageData("verificationPending")) {
        this.redirectToSignInPage()
      }
      if(responseJson.verification_method) {
        const authMethod = responseJson.verification_method === "sms" ? AuthenticationMethod.PHONE : AuthenticationMethod.EMAIL;
        this.setState({AuthMethod: authMethod})
    }
  }}
  getSubscription = async() => {
    const authToken = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubscriptionApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSubscriptionResponse = (id: string, message:Message) => {
    if(id === this.getSubscriptionCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.error) return
      if(responseJson.length) {
        this.validateEndDate(responseJson[0].end_date);
      }
      this.otpVerify("OTPVerification");
    }
  }
  validateEndDate = (date: string) => {
    const subscriptionEndDate = moment(date);
    const endDate = moment(subscriptionEndDate, moment.ISO_8601);
    const twoWeeksBefore = endDate.clone().subtract(15, 'days');

    const currentDate = moment();
    if (currentDate.isSameOrAfter(twoWeeksBefore) && currentDate.isSameOrBefore(endDate)) {
      localStorage.setItem("expiresIn", JSON.stringify(true));
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    }
    this.handleSubscriptionResponse(apiRequestCallId, message)
    this.handleResendCodeRes(apiRequestCallId, message)
    this.handleGetAuthResponse(apiRequestCallId, message)
    this.receive1(from, message)
    this.receive2(message)
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
